var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageCard',{attrs:{"pageName":" Client Pricing Schedules","iconClass":['fa', 'fa-tag', 'fa-lg']}},[_c('template',{slot:"page_content"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('v-wait',{attrs:{"for":"fetchingCPS"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":20}})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3 offset-sm-6 mb-3 text-right"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"tag":"button","to":{
                  path: '/super_admin/client_pricing_schedules/create',
                  params: { mode: 'Create' }
                }}},[_vm._v("Add New")])],1),_c('div',{staticClass:"col-sm-3 mb-3"},[_c('b-input-group',[_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-search"})])]),_c('b-form-input',{ref:"search",attrs:{"type":"text","placeholder":"Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"})]),_c('b-table',{attrs:{"items":_vm.clientPricingSchedules,"fields":_vm.fields,"show-empty":true,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"empty-text":"No records found.","responsive":"md","striped":"","hover":""},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(group_name)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.group_name)+" ("+_vm._s(data.item.group_id)+")")])]}},{key:"cell(actions)",fn:function(data){return [_c('a',{staticClass:"pl-2",attrs:{"href":"#"},on:{"click":function($event){return _vm.showPricingSchedule(data.item)}}},[_vm._v("Show")]),_c('router-link',{staticClass:"btn btn-link collapsed button-pad pr-2 edit-btn-link",attrs:{"tag":"button","to":{
                  path:
                    '/super_admin/client_pricing_schedules/' +
                    data.item.group_id,
                  params: { mode: 'Edit' }
                }}},[_vm._v("Edit")]),_c('a',{staticClass:"pl-2",attrs:{"href":"#"},on:{"click":function($event){return _vm.deletePricingSchedule(data.item)}}},[_vm._v("Delete")])]}}])}),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],2)],1)],1),_c('b-modal',{ref:"deleteConfirmation",attrs:{"id":"deleteConfirmation","title":_vm.deleteConfirmationTitle},on:{"ok":_vm.deleteClientPricingSchedule,"cancel":_vm.resetSelectedCPS}},[_c('p',[_vm._v(" "+_vm._s(_vm.deleteConfirmationMessage)+" ")])]),_c('show-pricing-schedule',{attrs:{"selectedPricingSchedule":_vm.selectedPricingSchedule}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }