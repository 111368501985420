<template>
  <PageCard
    pageName=" Client Pricing Schedules"
    :iconClass="['fa', 'fa-tag', 'fa-lg']"
  >
    <template slot="page_content">
      <b-row>
        <b-col sm="12">
          <v-wait for="fetchingCPS">
            <template slot="waiting">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>
            <div class="row">
              <div class="col-sm-3 offset-sm-6 mb-3 text-right">
                <router-link
                  tag="button"
                  class="btn btn-primary"
                  :to="{
                    path: '/super_admin/client_pricing_schedules/create',
                    params: { mode: 'Create' }
                  }"
                  >Add New</router-link
                >
              </div>
              <div class="col-sm-3 mb-3">
                <b-input-group>
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                  <b-form-input
                    type="text"
                    v-model="filter"
                    placeholder="Search"
                    ref="search"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12"></div>
            </div>

            <b-table
              :items="clientPricingSchedules"
              :fields="fields"
              :show-empty="true"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              @filtered="onFiltered"
              empty-text="No records found."
              responsive="md"
              striped
              hover
            >
              <template v-slot:cell(group_name)="data">
                <div>{{ data.item.group_name }} ({{ data.item.group_id }})</div>
              </template>
              <template v-slot:cell(actions)="data">
                <a href="#" @click="showPricingSchedule(data.item)" class="pl-2"
                  >Show</a
                >
                <router-link
                  tag="button"
                  class="btn btn-link collapsed button-pad pr-2 edit-btn-link"
                  :to="{
                    path:
                      '/super_admin/client_pricing_schedules/' +
                      data.item.group_id,
                    params: { mode: 'Edit' }
                  }"
                  >Edit</router-link
                >
                <a
                  href="#"
                  @click="deletePricingSchedule(data.item)"
                  class="pl-2"
                  >Delete</a
                >
              </template>
            </b-table>
            <b-row>
              <b-col md="6">
                <b-pagination
                  :total-rows="totalRows"
                  :per-page="perPage"
                  v-model="currentPage"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </v-wait>
        </b-col>
      </b-row>
      <b-modal
        id="deleteConfirmation"
        ref="deleteConfirmation"
        :title="deleteConfirmationTitle"
        @ok="deleteClientPricingSchedule"
        @cancel="resetSelectedCPS"
      >
        <p>
          {{ deleteConfirmationMessage }}
        </p>
      </b-modal>
      <show-pricing-schedule
        :selectedPricingSchedule="selectedPricingSchedule"
      ></show-pricing-schedule>
    </template>
  </PageCard>
</template>

<script>
import PageCard from '@/modules/core/components/layouts/PageCard'
import Vue from 'vue'
// ui components
import ShowPricingSchedule from './ShowPricingSchedule'

export default {
  name: 'ClientPricingSchedules',
  components: {
    PageCard,
    ShowPricingSchedule
  },
  created() {
    this.fetchingClientPricingSchedules()
  },
  data() {
    return {
      perPage: 20,
      totalRows: 1,
      filter: null,
      currentPage: 1,
      clientPricingSchedules: [],
      selectedPricingSchedule: {},
      deleteConfirmationMessage:
        'Along with pricing schedules, related single click defaults for this group will also get deleted. Are you sure you want to delete?',
      deleteConfirmationTitle: 'Delete Client Pricing Schedule',
      fields: [
        { key: 'group_name', label: 'Group Name', sortable: true },
        { key: 'group_type', label: 'Group Type', sortable: true },
        { key: 'auth_token', label: 'Auth Token', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    }
  },
  computed: {},
  methods: {
    setSelectedPricingSchedule(pricingSchedule) {
      this.selectedPricingSchedule = pricingSchedule
    },
    resetSelectedCPS() {
      this.selectedPricingSchedule = {}
    },
    fetchingClientPricingSchedules() {
      this.$wait.start('fetchingCPS')
      this.$http
        .get('/api/super_admin/client_pricing_schedules', {
          handleErrors: false
        })
        .then(res => {
          this.clientPricingSchedules = res.data.client_pricing_schedules.filter(
            cps => !(cps.group_type === 'Client Group' && cps.id == null)
          )
          this.totalRows = this.clientPricingSchedules.length
        })
        .then(() => {
          this.$wait.end('fetchingCPS')
        })
    },
    displayConfigurations(configurations) {
      return JSON.stringify(configurations)
    },
    deleteClientPricingSchedule() {
      this.$http
        .delete(
          '/api/super_admin/client_pricing_schedules/' +
            this.selectedPricingSchedule.group_id,
          {
            handleErrors: true
          }
        )
        .then(() => {
          let msg =
            this.selectedPricingSchedule.group_type === 'User Group'
              ? 'Pricing Schedule successfully deleted.'
              : 'Client Pricing Schedule successfully deleted.'
          Vue.toasted.show(msg, {
            icon: 'check-circle',
            type: 'success'
          })
          this.filter = null
          this.fetchingClientPricingSchedules()
          this.$bvModal.hide('deletePricingSchedule')
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showPricingSchedule(pricingSchedule) {
      this.setSelectedPricingSchedule(pricingSchedule)
      this.$bvModal.show('showPricingSchedule')
    },
    deletePricingSchedule(pricingSchedule) {
      this.setSelectedPricingSchedule(pricingSchedule)
      if (pricingSchedule.group_type === 'User Group') {
        this.deleteConfirmationMessage =
          'Are you sure you want to delete this Pricing Schedule?'
        this.deleteConfirmationTitle = 'Delete Pricing Schedule'
      } else {
        this.deleteConfirmationMessage =
          'Along with pricing schedules, related single click defaults for this group will also get deleted. Are you sure you want to delete?'
        this.deleteConfirmationTitle = 'Delete Client Pricing Schedule'
      }

      this.$bvModal.show('deleteConfirmation')
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse_td_tr {
  tr {
    vertical-align: middle !important;
  }
  td {
    padding: 0.1rem 0.75rem !important;
  }
  .table {
    background-color: transparent !important;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
  .button-pad {
    padding: 0px !important;
    cursor: pointer;
  }
}

.edit-btn-link {
  vertical-align: baseline;
}
</style>
