<template>
  <b-modal
    id="showPricingSchedule"
    ref="showPricingSchedule"
    :title="modalText"
    @shown="fetchPricingSchedules"
    @ok="closeModal"
    ok-only
    ok-title="Cancel"
    size="xl"
  >
    <v-wait for="fetchingPricingSchedules">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <div class="col-sm-3 offset-sm-9 mb-3">
          <b-input-group>
            <div class="input-group-prepend">
              <button type="button" class="btn btn-secondary">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <b-form-input
              type="text"
              v-model="filter"
              placeholder="Search"
              ref="search"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <b-table
        striped
        :items="pricingSchedules"
        :fields="fields"
        :filter="filter"
        caption-top
        responsive
      >
      </b-table>
    </v-wait>
  </b-modal>
</template>

<script charset="utf-8">
// api
import clientPricingScheduleAPI from '@/api/finapps/super_admin/client_pricing_schedules'

export default {
  name: 'ShowPricingSchedule',
  props: {
    selectedPricingSchedule: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      fields: [
        { key: 'product', label: 'Product', sortable: true },
        { key: 'key', label: 'Key', sortable: true },
        { key: 'range_element', label: 'Range Element', sortable: true },
        { key: 'range_min', label: 'Range Min', sortable: true },
        { key: 'range_max', label: 'Range Max', sortable: true },
        { key: 'value', label: 'Value', sortable: true }
      ],
      pricingSchedules: [],
      filter: null
    }
  },
  computed: {
    modalText() {
      return (
        this.selectedPricingSchedule.group_name + ' Client Pricing Schedule'
      )
    }
  },
  methods: {
    fetchPricingSchedules() {
      this.$wait.start('fetchingPricingSchedules')
      clientPricingScheduleAPI
        .pricingSchedules(this.selectedPricingSchedule.id)
        .then(res => {
          this.pricingSchedules = res.client_pricing_schedule.pricing_schedules
          this.$wait.end('fetchingPricingSchedules')
        })
    },
    closeModal() {
      this.$bvModal.hide('showPricingSchedule')
    }
  }
}
</script>
