import axios from 'axios'

export default {
  pricingSchedules(cpsID) {
    return axios
      .get(
        `/api/super_admin/client_pricing_schedules/${cpsID}/pricing_schedules`,
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  generateToken() {
    return axios
      .get('/api/super_admin/client_pricing_schedules/generate_token', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
